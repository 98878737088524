export interface MyPatientsSortParams {
  sortField: string;
  sortDir: 'asc' | 'desc';
}

export enum MyPatientsRoutes {
  MyPatients = 'my-patients',
  MyPatientsWaitingTyping = 'my-patients.patients-waiting-typing',
  MyPatientsReceivedTyping = 'my-patients.patients-typing-received',
}
